module.exports = {
  pathPrefix: '/',
  title: 'KN',
  titleAlt: 'Karolis Narkevicius', // Title for JSONLD
  description: 'My very own corner of the web',
  headline: 'About me, my blog, projects and more', // Headline for schema.org JSONLD
  url: 'https://www.kn8.lt',
  siteLanguage: 'en',
  logo: '/logos/logo.png', // Used for SEO
  ogLanguage: 'en_US', // OG Language
  // JSONLD / Manifest
  favicon: 'static/favicon.png', // Used for manifest favicon generation
  shortName: 'KN', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Karolis Narkevicius', // Author for schemaORGJSONLD
  twitter: '@KidkArolis',
  github: 'KidkArolis',
  // a11y
  skipNavId: 'reach-skip-nav' // ID for the "Skip to content" a11y feature
}
