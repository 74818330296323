import React from 'react'
import { Global, css } from '@emotion/core'
import '@reach/skip-nav/styles.css'
import { theme, reset } from '../styles'
import 'focus-visible'
import 'typeface-rubik'
import 'typeface-roboto-mono'

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 3px solid ${theme.colors.primary};
    border-radius: 3px;
  }
  .focus-visible {
    box-shadow: 0 0 0 3px ${theme.colors.primary};
    border-radius: 3px;
  }
  a {
    color: ${theme.colors.link};
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
  ${theme.media.mobile} {
    html {
      font-size: 16px !important;
    }
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`

export default () => <Global styles={globalStyle} />
