import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import GlobalStyles from './GlobalStyles'
import SEO from './SEO'
import SkipNavLink from './SkipNavLink'
import Hamburger from './Hamburger'
import { theme } from '../styles'

const styles = {
  logo: {
    fontSize: 14,
    fontWeight: 600,
    display: 'block',
    a: {
      fontFamily: 'Rubik',
      color: 'black',
      display: 'inline-block',
      fontSize: 14,
      fontWeight: 500,
      padding: '4px 0'
    },
    'a:hover': {
      border: 'none'
    }
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    [theme.media.desktop]: {
      paddingLeft: 80,
      paddingRight: 80,
      paddingBottom: 10,
      paddingTop: 6
    }
  },
  peach: {
    [theme.media.mobile]: {
      // background: 'rgb(255,247,241)'
      background: 'rgb(255, 239, 228)'
    }
  },
  mobileNav: {
    a: {
      fontFamily: 'Rubik',
      color: 'black',
      display: 'block',
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 400,
      padding: 4,
      marginBottom: 12
    },
    [theme.media.mobile]: {
      display: 'block'
    },
    [theme.media.desktop]: {
      display: 'none'
    }
  },
  desktopNav: {
    a: {
      fontFamily: 'Rubik',
      color: 'black',
      fontSize: 14,
      fontWeight: 500,
      padding: 4,
      marginLeft: 10
    },
    [theme.media.mobile]: {
      display: 'none'
    },
    [theme.media.desktop]: {
      display: 'block'
    }
  }
}

function Nav({ onClick }) {
  return (
    <>
      <Link onClick={onClick} to='/'>
        Home.
      </Link>
      <Link onClick={onClick} to='/blog'>
        Blog.
      </Link>
      <Link onClick={onClick} to='/projects'>
        Projects.
      </Link>
      <Link onClick={onClick} to='/interests'>
        Interests.
      </Link>
      <Link onClick={onClick} to='/albums'>
        Albums.
      </Link>
      <a onClick={onClick} href='https://twitter.com/KidkArolis' target='_blank' rel='noopener'>
        Twitter.
      </a>
    </>
  )
}

Nav.propTypes = {
  onClick: PropTypes.func
}

const PureLayout = ({ logo, customSEO, children }) => (
  <ThemeProvider theme={theme}>
    <>
      <Helmet>
        <link rel='shortcut icon' type='image/png' href='/favicon.png' />
      </Helmet>
      <GlobalStyles />
      <SkipNavLink />
      {!customSEO && <SEO />}
      <div css={[styles.nav, !logo && styles.peach]}>
        <div css={styles.logo}>
          {logo && (
            <Link to='/'>
              <span role='img' aria-label='wave'>
                👋
              </span>{' '}
              KN
            </Link>
          )}
        </div>
        <div css={styles.desktopNav}>
          <Nav />
        </div>
        <div css={styles.mobileNav}>
          <Hamburger>{({ onClick }) => <Nav onClick={onClick} />}</Hamburger>
        </div>
      </div>
      {children}
    </>
  </ThemeProvider>
)

class Layout extends Component {
  render() {
    return <PureLayout {...this.props}>{this.props.children}</PureLayout>
  }
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired
}

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  logo: PropTypes.bool,
  customSEO: PropTypes.bool
}

PureLayout.defaultProps = {
  customSEO: false
}
