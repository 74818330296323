import React from 'react'
import PropTypes from 'prop-types'

class Icon extends React.Component {
  renderGraphic() {
    switch (this.props.icon) {
      case 'launch':
        return (
          <g>
            <path d='M19 19h-14v-14h7v-2h-7c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zm-5-16v2h3.59l-9.83 9.83 1.41 1.41 9.83-9.83v3.59h2v-7h-7z' />
          </g>
        )
      case 'play':
        return (
          <g>
            <path d='M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
          </g>
        )
    }
  }
  render() {
    let styles = {
      fill: 'currentcolor',
      verticalAlign: 'middle',
      marginBottom: 2,
      width: this.props.size, // CSS instead of the width attr to support non-pixel units
      height: this.props.size // Prevents scaling issue in IE
    }
    return (
      <svg
        viewBox='0 0 24 24'
        preserveAspectRatio='xMidYMid meet'
        fit='true'
        style={{
          ...styles,
          ...this.props.style
        }}
      >
        {this.renderGraphic()}
      </svg>
    )
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object
}

Icon.defaultProps = {
  size: 16
}

export default Icon
