import React from 'react'
import PropTypes from 'prop-types'
import { theme } from '../styles'

export default function Title({ css, children }) {
  return <h2 css={{ ...styles.title, ...css }}>{children}</h2>
}

Title.propTypes = {
  css: PropTypes.object,
  children: PropTypes.node
}

const styles = {
  title: {
    marginTop: '6rem',
    [theme.media.desktop]: {
      marginTop: '6rem'
    }
  }
}
