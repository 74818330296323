import SEO from './SEO'
import Layout from './Layout'
import Listing from './Listing'
import Title from './Title'
import Subtitle from './Subtitle'
import Container from './Container'
import Icon from './Icon'
import Wave from './Wave'
import Peach from './Peach'
import Footer from './Footer'

export { Layout, SEO, Listing, Container, Title, Subtitle, Icon, Wave, Peach, Footer }
