import React from 'react'
import PropTypes from 'prop-types'
import { theme } from '../styles'

export default function Subtitle({ children }) {
  return <p css={{ ...styles.subtitle }}>{children}</p>
}

Subtitle.propTypes = {
  children: PropTypes.node
}

const styles = {
  subtitle: {
    fontSize: 18,
    [theme.media.desktop]: {
      fontSize: 22
    }
  }
}
