import React, { useState } from 'react'
import PropTypes from 'prop-types'

export default function Hamburger({ children }) {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <button css={[styles.hamburger, styles.squeeze]} type='button' onClick={() => setOpen(!isOpen)} aria-label='menu'>
        <span css={styles.box}>
          <div css={[styles.bar, styles.mid, isOpen && styles.activeMid]}>
            <div css={[styles.bar, styles.top, isOpen && styles.activeTop]} />
            <div css={[styles.bar, styles.bot, isOpen && styles.activeBot]} />
          </div>
        </span>
      </button>
      {isOpen && <div css={styles.overlay}>{children({ onClick: () => setOpen(false) })}</div>}
    </>
  )
}

Hamburger.propTypes = {
  children: PropTypes.func
}

const styles = {
  overlay: {
    position: 'absolute',
    background: 'white',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hamburger: {
    '-webkit-appearance': 'none',
    paddingTop: '2px',
    paddingLeft: 0,
    paddingRight: 0,
    right: '2rem',
    display: 'inline-block',
    cursor: 'pointer',
    transitionProperty: 'opacity, filter',
    transitionDuration: '0.15s',
    transitionTimingFunction: 'linear',
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: 0,
    margin: 0,
    zIndex: 42,
    position: 'absolute',
    overflow: 'visible',
    ':hover': {
      opacity: 0.7
    }
  },
  box: {
    width: '20px',
    height: '24px',
    display: 'inline-block',
    position: 'relative'
  },
  bar: {
    width: '20px',
    height: '2px',
    backgroundColor: '#000',
    borderRadius: '10px',
    position: 'absolute',
    transitionProperty: 'transform',
    transitionDuration: '0.15s',
    transitionTimingFunction: 'ease'
  },
  top: {
    top: '-6px'
  },
  mid: {
    top: '11px'
  },
  bot: {
    bottom: '-6px'
  },
  activeMid: {
    transform: 'rotate(45deg)',
    transitionDelay: '0.12s',
    transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
  },
  activeTop: {
    top: 0,
    opacity: 0,
    transition: 'top 0.075s ease, opacity 0.075s 0.12s ease'
  },
  activeBot: {
    bottom: 0,
    transform: 'rotate(-90deg)',
    transition: 'bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)'
  }
}
