const theme = {
  fonts: {
    mono: `"Roboto Mono", Monaco, 'Courier New', Courier, monospace`
  },
  colors: {
    primary: '#003dd1',
    link: '#003dd1',
    bg: '#fff',
    black: '#000',
    grey: '#595C62',
    greyDark: '#303643',
    greyDarker: '#1c252b'
  },
  media: {
    mobile: '@media (max-width: 767px)',
    desktop: '@media (min-width: 768px)'
  },
  maxWidth: 720
}

export default theme
