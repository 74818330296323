import React from 'react'

export default function Peach() {
  return (
    <div css={styles.container}>
      <div css={styles.moji}>
        <span role='img' aria-label='peach'>
          🍑
        </span>
      </div>
    </div>
  )
}

const styles = {
  container: {
    border: '1px solid rgba(0,0,0,0.05)',
    background: 'rgba(255,255,255,0.7)',
    boxShadow: '0 1px 6px 2px rgba(0,0,0,0.02)',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    cursor: 'default'
  },
  moji: {
    fontSize: '20px'
  }
}
