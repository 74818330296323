import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import { Link } from 'gatsby'
import { theme, prism } from '../styles'

export default function Listing({ items, mojy, spaced, compact }) {
  return (
    <ul css={styles.list}>
      {items.map(item => (
        <li key={item.id} id={item.id} css={[styles.item, compact && styles.compact]}>
          <span css={styles.headline}>{item.headline}</span>
          {item.link.startsWith('/') ? (
            <Link css={styles.link} to={item.link}>
              {item.moji} {item.title}
            </Link>
          ) : (
            <a css={[styles.link, mojy && styles.mojy]} href={item.link} target='_blank' rel='noopener'>
              {item.moji} {item.title} <Icon icon='launch' style={{ opacity: 0.5 }} />
            </a>
          )}
          {item.blurb && (
            <div css={[prism, spaced && styles.spaced]} dangerouslySetInnerHTML={{ __html: item.blurb }} />
          )}
        </li>
      ))}
    </ul>
  )
}

Listing.propTypes = {
  items: PropTypes.array.isRequired,
  mojy: PropTypes.bool,
  spaced: PropTypes.bool
}

const styles = {
  list: {
    marginBottom: 0,
    listStyleType: 'none',
    marginLeft: 0,
    marginTop: '4rem',
    [theme.media.desktop]: {
      marginBottom: '2rem'
    }
  },
  item: {
    marginBottom: '4rem'
  },
  compact: {
    marginBottom: '1.5rem'
  },
  headline: {
    display: 'block',
    marginTop: '1.8rem',
    marginBottom: 0,
    opacity: 0.7,
    fontFamily: theme.fonts.mono,
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'uppercase'
  },
  link: {
    fontSize: 16,
    fontWeight: 600,
    color: 'black',
    textDecoration: 'none',
    [theme.media.desktop]: {
      fontSize: 20
    }
  },
  mojy: {
    [theme.media.desktop]: {
      marginLeft: -24
    }
  },
  spaced: {
    paddingBottom: '4rem',
    ':last-of-type': {
      paddingBottom: 0
    }
  }
}
