import React from 'react'
import PropTypes from 'prop-types'
import website from '../../config/website'
import { theme } from '../styles'

export default function Container({ main, css, children }) {
  return (
    <div id={main && website.skipNavId} css={{ ...styles.container, ...css }}>
      {children}
    </div>
  )
}

Container.propTypes = {
  main: PropTypes.bool,
  css: PropTypes.object,
  children: PropTypes.node
}

const styles = {
  container: {
    maxWidth: '645px',
    margin: '0 auto',
    padding: '0 1.25rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    [theme.media.desktop]: {
      padding: '0 2rem',
      paddingTop: '2rem',
      paddingBottom: '8rem'
    }
  }
}
