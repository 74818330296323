import React, { useState, useEffect } from 'react'
import { keyframes } from '@emotion/core'

export default function Wave() {
  const [isHi5, setHi5] = useState(false)
  const [count, setCount] = useState(0)

  useEffect(() => {
    // on each click
    if (count === 0) return
    // reset the animation
    setHi5(false)
    // and in the next tick
    let t = setTimeout(() => {
      // kick it off again
      setHi5(true)
      // and ~after the animation completes
      // reset the hi5 state back for next hit
      t = setTimeout(() => setHi5(false), count % 4 === 3 ? 800 : 400)
    }, 1)

    return () => clearTimeout(t)
  }, [count])

  return (
    <button
      css={{
        ...styles.button,
        ...(isHi5 && styles.hi5),
        ...(isHi5 && count % 4 === 3 && styles.big),
        ...(count > 3 && count < 7 && styles.fist)
      }}
      onClick={() => setCount(count => count + 1)}
    >
      <span css={styles.moji} role='img' aria-label='wave'>
        {[`👋`, `👊`, `✌️`, `👏`, `😐`, `😅`, `💥`][Math.floor(count / 4) % 7]}
      </span>
    </button>
  )
}

const wave = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(10deg);
  }
`

const hi5 = keyframes`
  0% {
    
  }
  60% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const big = keyframes`
  0% {
    
  }
  60% {
    transform: scale(2.3);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const styles = {
  button: {
    border: 'none',
    background: 'none',
    padding: 0,
    marginBottom: '1rem',
    cursor: 'grab',
    ':focus': {
      outline: 'none'
    },
    ':hover span': {
      animationPlayState: 'running'
    }
  },
  fist: {
    cursor: 'grabbing'
  },
  moji: {
    fontSize: 36,
    display: 'block',
    // width: 40
    animation: `${wave} 0.6s alternate infinite`,
    animationPlayState: 'paused'
  },
  hi5: {
    width: 40,
    animation: `${hi5} 0.3s forwards`
  },
  big: {
    animation: `${big} 0.7s forwards`
  }
}
