import React from 'react'
import Peach from './Peach'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { theme } from '../styles'

export default function Footer({ children }) {
  return (
    <footer css={styles.footer}>
      <div css={styles.footerBlock}>{children}</div>
      <div css={[styles.footerBlock, styles.mojiContainer]}>
        <Peach />
      </div>
      <div css={[styles.footerBlock, styles.me]}>
        <strong>Karolis Narkevičius</strong>
        <br /> Co-founder at{' '}
        <a href='https://humaans.io/' target='_blank' rel='noopener'>
          Humaans
        </a>{' '}
        – the best in class modern HR software with everything you need to onboard, manage and grow your employees.
        <br />
        <Link to={'/'}>See more →</Link>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  children: PropTypes.any
}

const styles = {
  footer: {
    background: 'rgb(255,247,241)'
  },
  footerBlock: {
    maxWidth: '740px',
    padding: '0 1.25rem',
    [theme.media.desktop]: {
      padding: '0 2rem'
    },
    margin: 'auto',
    ':first-of-type': {
      paddingTop: '4rem',
      paddingBottom: '0rem'
    },
    ':last-of-type': {
      paddingBottom: '4rem'
    },
    ':empty': {
      padding: 0
    }
  },
  mojiContainer: {
    display: 'flex',
    padding: '3rem 1.25rem 1rem 1.25rem',
    [theme.media.desktop]: {
      padding: '3rem 2rem 1rem 2rem'
    }
  }
}
